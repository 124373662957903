<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">
          Fahrschule Grütter Stooss AG - Fahrschule und Kurse in Gösgen, Olten, Aarau
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-2">
              <img src="https://gruetter-fahrschule.ch/wp-content/uploads/2016/07/logo_dark.png"
                   alt="Grütter Stooss AG Logo" />
            </div>
            <div class="col-10">
              <p>
                Hier findest du alle Kurse der <a href="//gruetter-fahrschule.ch">Fahrschule Grütter Stooss AG</a> aus
                Niedergösgen. Einfach online anmelden für VKU, Motokurse und Fahrkurse.
              </p>
              <p>
                Wir bieten VKU-Kurse (Abendkurse oder Samstag), Motorradkurse (Kursteil 1, 2 oder 3) und Spezialkurse
                an.
              </p>
            </div>
          </div>
        </div>
      </div>

      <course-list-component :courses="courses"></course-list-component>
    </div>
  </div>
</template>

<script>
import CourseListComponent from '@/views/Components/CourseListComponent';
import CourseService from '@/services/CourseService';

export default {
  name: 'Dashboard',
  components: {CourseListComponent},
  data() {
    return {
      courses: null
    };
  },
  mounted() {
    this.loadCourses();
  },
  methods: {
    loadCourses() {
      CourseService
          .getCourseList()
          .then(response => this.courses = response.data);
    }
  }
};
</script>
